import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { FaStackOverflow, FaTwitter, FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { IconContext } from 'react-icons';

import Seo from '../components/Seo';
import Header from '../components/Header';
import Footer from '../components/Footer';

import './index.scss';

class Home extends React.Component {
  render() {
    return (
      <div className="container">
        <Seo />
        <Header />
        {this.renderIntroSection()}
        {this.renderBlogSection()}
        {this.renderAssociationsSection()}
        <Footer />
      </div>
    );
  }

  renderIntroSection = () => {
    return (
      <section className="col-md-12 d-flex flex-column justify-content-center align-items-center main-intro-container">
        <h1 className="name-head">Shubham Bhardwaj</h1>
        <h3 className="main-intro">
          Software Engineer, focused on creating digital products that stand out from the rest.
        </h3>
        {this.renderSocialIconRow()}
      </section>
    );
  };

  renderSocialIconRow = () => {
    return (
      <div className="d-flex flex-row justify-content-between socialIconRow">
        {this.renderSocialIcon({
          className: 'socialIcon twitterIcon',
          href: 'https://twitter.com/shbhmbhrdwj',
          renderIcon: () => <FaTwitter />
        })}
        {this.renderSocialIcon({
          className: 'socialIcon linkedInIcon',
          href: 'https://www.linkedin.com/in/shubhambhardwaj1/',
          renderIcon: () => <FaLinkedinIn />
        })}
        {this.renderSocialIcon({
          className: 'socialIcon',
          href: 'https://github.com/shbmbhrdwj',
          renderIcon: () => <FaGithub />
        })}
        {this.renderSocialIcon({
          className: 'socialIcon stackOverflowIcon',
          href: 'https://stackoverflow.com/users/7629550/shubham-bhardwaj',
          renderIcon: () => <FaStackOverflow />
        })}
      </div>
    );
  };

  renderSocialIcon = ({ className, href, renderIcon }) => {
    return (
      <IconContext.Provider value={{ className }}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {renderIcon()}
        </a>
      </IconContext.Provider>
    );
  };

  renderBlogSection = () => {
    return (
      <section className="blog-showcase-section">
        <h3>Recent Writings</h3>
        {this.renderBlogItems()}
      </section>
    );
  };

  renderBlogItems = () => {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    const noOfPosts = data.allMarkdownRemark.totalCount;

    if (noOfPosts === 0) {
      return this.renderEmptyBlogSection();
    }

    if (noOfPosts < 3) {
      return this.renderBlogItemRow(posts);
    }

    return (
      <>
        {this.renderBlogItemRow(posts.slice(0, 2))}
        {this.renderBlogItemRow(posts.slice(2), true)}
      </>
    );
  };

  renderEmptyBlogSection = () => {
    return (
      <div className="empty-blog-section">
        <h4>Oops! There&apos;s nothing here! Something cool is coming here!</h4>
      </div>
    );
  };

  renderBlogItemRow = (posts, reverse = false) => {
    return (
      <div className="d-md-flex flex-md-row">
        {posts.map(({ node }, index) => this.renderBlogItem({ node, index, reverse }))}
      </div>
    );
  };

  renderBlogItem = ({ node, reverse = false, index }) => {
    const { featuredImage, date } = node.frontmatter;
    const { timeToRead } = node;
    let className = index % 2 !== 0 ? 'col-md-5' : 'col-md-7';
    if (reverse) {
      className = index % 2 !== 0 ? 'col-md-7' : 'col-md-5';
    }
    const meta = `${date} · ${timeToRead} min read`;
    return (
      <div className={className} key={index}>
        <Link
          style={{ boxShadow: `none`, textDecoration: 'none', color: 'black' }}
          to={node.fields.slug}
        >
          <div className="home-blog-item-container">
            <Image className="blog-item-image" fluid={featuredImage.childImageSharp.fluid} />
            <h2>{node.frontmatter.title || node.fields.slug}</h2>
            <p>{node.frontmatter.description || node.excerpt}</p>
            <div className="blog-item-meta">{meta}</div>
          </div>
        </Link>
      </div>
    );
  };

  renderAssociationsSection = () => {
    const { data } = this.props;
    const { allImageSharp } = data;
    const { edges } = allImageSharp;
    return (
      <section className="associations-section">
        <h3>Associations</h3>
        <div className="d-flex flex-row flex-wrap">
          {edges.map(edge => this.renderAssociationsIcons(edge.node.childImageSharp.sizes))}
        </div>
      </section>
    );
  };

  renderAssociationsIcons = image => {
    return (
      <div style={{ width: 200, margin: 20, alignSelf: 'center' }}>
        <Image
          className="blog-item-image"
          sizes={image}
          style={{ maxHeight: 100 }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </div>
    );
  };
}

Home.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
      totalCount: PropTypes.number
    }),
    allImageSharp: PropTypes.shape({
      edges: PropTypes.array
    })
  }).isRequired
};

export default Home;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 4) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            type
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1240) {
                  src
                  aspectRatio
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
    allImageSharp: allFile(filter: { relativePath: { regex: "/associations/" } }) {
      edges {
        node {
          id
          childImageSharp {
            sizes(maxWidth: 200, grayscale: true) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
  }
`;
